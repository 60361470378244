import {UrlParameterTypes} from '@dv/kitadmin/core/uirouter';
import type {KitAdminState} from '@dv/kitadmin/models';
import {KinderOrt} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {RequiredPermission} from '@dv/shared/code';
import moment from 'moment';

export const WORK_TIME_CONTROLLING_KINDERORT_STATE = {
    name: 'base.kinderort.work-time-controlling',
    url: '/arbeitszeitkontrolle?:date',
    data: {
        subtitle: 'COMMON.NAV_WORK_TIME_CONTROLLING',
        permission(toParams: { id: string }): RequiredPermission {
            return PERMISSION.WORK_TIME_CONTROLLING.WORK_TIME_CONTROLLING_TABLE_VIEW + toParams.id;
        },
    },
    params: {
        date: {
            type: UrlParameterTypes.MOMENT_DATE,
            dynamic: true,
        },
    },
    paramDeclaration: {
        date: moment,
        kinderOrt: KinderOrt,
    },
} satisfies KitAdminState;
